.video{
    /* left:50%; top:50%; height: 100%; */
    /* transform: translate(-50%, -50%);  */
     object-fit: cover;
    width: 100%;
    z-index: -2; 
    position: absolute;

  }

